export default {
  "2d": "mdi-checkerboard",
  "3d": "mdi-rotate-3d",
  account: "mdi-account-circle",
  add: "mdi-plus",
  add_note: "mdi-message-plus",
  analysis: "mdi-chart-box",
  apply: "mdi-check",
  arrow_back: "mdi-arrow-left",
  arrow_drop_down: "mdi-menu-down",
  autofix: "mdi-auto-fix",
  backspace: "mdi-backspace",
  backward: "mdi-chevron-left",
  board: "mdi-checkerboard",
  board_style: "mdi-apps",
  bot: "mdi-robot",
  bot_off: "mdi-robot-dead-outline",
  bot_on: "mdi-robot-happy",
  branch: "mdi-directions-fork", // rotate-180
  browse_fs: "mdi-folder-open",
  caps1: "mdi-circle",
  caps2: "mdi-circle-outline",
  chat: "mdi-forum-outline",
  clear: "mdi-close-circle",
  clipboard: "mdi-clipboard",
  clock: "mdi-timer",
  close: "mdi-close",
  close_multiple: "mdi-close-box-multiple",
  color: "mdi-palette",
  copy: "mdi-content-copy",
  date_arrow_left: "mdi-calendar-arrow-left",
  date_arrow_right: "mdi-calendar-arrow-right",
  date: "mdi-calendar",
  date_time: "mdi-calendar-clock",
  delete: "mdi-delete",
  depth: "mdi-arrow-collapse-down",
  down: "mdi-chevron-down",
  download: "mdi-download",
  edit: "mdi-pencil",
  email: "mdi-email",
  embed: "mdi-code-tags",
  error: "mdi-alert-circle",
  event: "mdi-trophy",
  event_outline: "mdi-trophy-outline",
  file: "mdi-file",
  first: "mdi-page-first",
  flat_counts: "mdi-numeric-1-box",
  flats1: "mdi-square",
  flats2: "mdi-square-outline",
  flip_horizontal: "mdi-flip-horizontal",
  flip_vertical: "mdi-flip-vertical",
  forward: "mdi-chevron-right",
  fullscreen: "mdi-fullscreen",
  fullscreen_exit: "mdi-fullscreen-exit",
  gif: "mdi-gif",
  header: "mdi-code-brackets",
  help: "mdi-help-circle",
  highlighter: "mdi-format-color-highlight",
  info: "mdi-information",
  int_analysis: "mdi-refresh",
  json: "mdi-code-json",
  keyboard: "mdi-keyboard-variant",
  komi: "mdi-scale-balance",
  last: "mdi-page-last",
  less: "mdi-unfold-less-horizontal",
  list: "mdi-view-list",
  local: "mdi-monitor-cellphone",
  make_main: "mdi-arrow-collapse-up",
  menu_horizontal: "mdi-dots-horizontal",
  menu_vertical: "mdi-dots-vertical",
  more: "mdi-unfold-more-horizontal",
  moves: "mdi-format-list-numbered",
  notes: "mdi-message-bulleted",
  notes_off: "mdi-message-bulleted-off",
  ongoing: "mdi-motion-play-outline",
  online: "mdi-cloud",
  online_private: "mdi-cloud-lock",
  open_game: "mdi-alert-circle-outline",
  open_in_new: "mdi-open-in-new",
  opening: "mdi-ray-start-arrow",
  password: "mdi-lock",
  pause: "mdi-pause",
  play: "mdi-play",
  player1: "mdi-account",
  player1_private: "mdi-account-lock",
  player2: "mdi-account-outline",
  player2_private: "mdi-account-lock-outline",
  players: "mdi-account-multiple-outline",
  players_private: "mdi-account-multiple-outline",
  ply: "mdi-layers",
  png: "mdi-file-image",
  points: "mdi-star-circle",
  promote: "mdi-arrow-up",
  puzzle: "mdi-puzzle",
  qrcode: "mdi-qrcode",
  random: "mdi-dice-3",
  rating1: "mdi-star",
  rating2: "mdi-star-outline",
  recent: "mdi-backup-restore",
  redo: "mdi-redo",
  refresh: "mdi-refresh",
  result: "mdi-gavel",
  ring1: "mdi-square-outline",
  ring2: "mdi-square-medium-outline",
  road_connections: "mdi-vector-square",
  rotate_180: "mdi-cached",
  rotate_left: "mdi-rotate-left",
  rotate_right: "mdi-rotate-right",
  round: "mdi-repeat",
  scratch_board: "mdi-checkerboard-plus",
  search: "mdi-magnify",
  send: "mdi-send",
  settings: "mdi-cog",
  share: "mdi-share-variant",
  site: "mdi-map-marker",
  size: "mdi-ruler-square",
  spectator: "mdi-account-group",
  spectator_private: "mdi-lock",
  stack_counts: "mdi-numeric-2-box-multiple",
  success: "mdi-check-circle",
  swap_vert: "mdi-swap-vertical",
  table: "mdi-table-large",
  text: "mdi-text",
  time: "mdi-clock-outline",
  to_top: "mdi-arrow-collapse-up",
  top_games: "mdi-trophy",
  trim: "mdi-content-cut",
  ui: "mdi-view-array-outline",
  undo: "mdi-undo",
  up: "mdi-chevron-up",
  update: "mdi-cached",
  url: "mdi-link",
  url_short: "mdi-link-box-outline",
  visibility: "mdi-eye",
  visibility_off: "mdi-eye-off",
  warning: "mdi-alert",
};
