export default {
  site_name: "ptn.ninja",
  app_title: "PTN Ninja",

  About: "About",
  Account: "Account",
  Action: "Action",
  "Add Game": "Load/Create Game",
  Analysis: "Analysis | Analyses",
  "Animate Board": "Animate Board",
  "Animate while scrubbing": "Animate while scrubbing",
  Apply: "Apply",
  "Apply Transformation": "Apply Transformation",
  Autodetect: "Autodetect",
  "Axis Labels": "Axis Labels",
  "Background Opacity": "Background Opacity",
  Backward: "Backward",
  "Backward Half-Step": "Backward Half-Step",
  Beginning: "Beginning",
  Board: "Board",
  "Board Preferences": "Board Preferences",
  "Board Preferences Button": "Board Preferences Button",
  "Board Style": "Board Style",
  Bot: "Bot",
  Branches: "Branches",
  "2D Board": "2D Board",
  "3D Board": "3D Board",
  Cancel: "Cancel",
  "Cancel Move": "Cancel Move",
  Caps: "Caps",
  Caps1: "Caps 1",
  Caps2: "Caps 2",
  Changelog: "Changelog",
  Chat: "Chat",
  Checker: "Checker",
  Clear: "Clear",
  Clipboard: "Clipboard",
  Clock: "Clock",
  Close: "Close",
  Colors: "Colors",
  "Coming soon": "Coming soon!",
  "Configure GIF": "Configure GIF",
  "Configure PNG": "Configure PNG",
  Confirm: "Confirm",
  Copy: "Copy",
  "Copy URL": "Copy URL",
  Create: "Create",
  "Current Move": "Current Move",
  "Custom Color": "Custom Color",
  Date: "Date",
  DateTime: "Date/Time",
  Delete: "Delete",
  "Delete Ply": "Delete Ply",
  Dismiss: "Dismiss",
  Download: "Download",
  Duplicate: "Duplicate",
  "Duplicate Game Names": "Duplicate Game Names from URL",
  Edit: "Edit",
  "Edit Game": "Edit Game",
  "Edit PTN": "Edit PTN",
  "Email Address": "Email Address",
  Embed: "Embed",
  End: "End",
  "Evaluation Bars": "Evaluation Bars",
  "Evaluation Text": "Evaluation Text",
  Event: "Event",
  "Export PNG Image": "Export PNG Image",
  "Export PTN File": "Export PTN File",
  Files: "Files",
  "First Branch": "First Branch",
  "Flat Counts": "Flat Counts",
  Flats: "Flats",
  Flats1: "Flats 1",
  Flats2: "Flats 2",
  "Flip Horizontally": "Flip Horizontally",
  "Flip Vertically": "Flip Vertically",
  "Focus Game Selector": "Focus Game Selector",
  Forward: "Forward",
  "Forward Half-Step": "Forward Half-Step",
  FPM: "FPM", // Frames Per Minute
  "From Center": "From Center",
  "Full Link": "Full Link",
  Fullscreen: "Fullscreen",
  "Game Info": "Game Info",
  "Game Notifications": "Game Notifications",
  "Game Options": "Game Options",
  "Game x closed": 'Closed "{game}"',
  Generate: "Generate",
  Guest: "Guest",
  Height: "Height",
  Help: "Help",
  "Hide Analysis": "Hide Analysis",
  "Hide Chat": "Hide Chat",
  "Hide Header Tags": "Hide Header Tags",
  "Hide Notes": "Hide Notes",
  "Hide Other Lines": "Hide Other Lines",
  "Hide PTN": "Hide PTN",
  "Highlight Squares": "Highlight Squares",
  Highlighter: "Highlighter",
  Hotkey: "Hotkey",
  Hotkeys: "Hotkeys",
  "Image Size": "Image Size",
  Import: "Import",
  "Import from Clipboard": "Import from Clipboard",
  "Player Names": "Player Names",
  "Join Game": "Join Game",
  Key: "Key",
  Komi: "Komi",
  "Last Branch": "Last Branch",
  Less: "Less",
  Link: "Link",
  "Link to Game": "Link to Game",
  "Link to Position": "Link to Position",
  "List View": "List View",
  "Load Files": "Load Files",
  "Load Game": "Load Game",
  "Load Online Game": "Load Online Game",
  Loading: "Loading...",
  Local: "Local",
  "Local Game": "Local Game",
  "Log In": "Log In",
  "Log Out": "Log Out",
  "Make Main": "Make Main",
  "Manual Mode": "Manual Mode",
  Message: "Message",
  "Minimum Rating": "Minimum Rating",
  More: "More",
  Move: "Move",
  "Move Number": "Move Number",
  Moves: "Moves",
  "Multiple Files": "Multiple Files",
  n_games: "No games | 1 game | {count} games",
  Name: "Name",
  "Native Sharing": "Native Sharing",
  Negative: "Negative",
  "New Analysis": "New Analysis",
  "New Game": "New Game",
  "New Password": "New Password",
  "New Puzzle": "New Puzzle",
  "Next Branch": "Next Branch",
  "No Games": "Unable to find any games",
  None: "None",
  Note: "Note",
  "Note Notifications": "Note Notifications",
  Notes: "Notes",
  OK: "OK",
  Ongoing: "Ongoing",
  Online: "Online",
  "Online Game": "Online Game | Online Games",
  Opacity: "Opacity",
  Open: "Open", // Games with an open seat
  Opening: "Opening",
  "Opponent Name": "Opponent Name",
  Orthogonal: "Orthogonal",
  "Orthogonal Board": "Orthogonal Board",
  Padding: "Padding",
  Password: "Password",
  Perspective: "Perspective",
  "Piece Shadows": "Piece Shadows",
  Play: "Play",
  "Play/Pause": "Play/Pause",
  "Play Button": "Play Button",
  "Play Controls": "Play Controls",
  "Play Mode": "Play Mode",
  "Play Online": "Play Online",
  "Play Speed": "Play Speed",
  Player1: "Player 1",
  Player2: "Player 2",
  "Player Name": "Player Name",
  Players: "Players",
  "PlayTak Game ID": "PlayTak Game ID",
  Plies: "Plies",
  Ply: "Ply",
  Points: "Points",
  Positive: "Positive",
  Preferences: "Preferences",
  "Previous Branch": "Previous Branch",
  "Previous Game": "Previous Game",
  Private: "Private",
  "Private Game": "Private Game",
  Promote: "Promote",
  PTN: "PTN",
  "PTN File": "PTN File",
  Public: "Public",
  Puzzle: "Puzzle | Puzzles",
  "QR Code": "QR Code",
  Random: "Random",
  Rating1: "Rating 1",
  Rating2: "Rating 2",
  Recent: "Recent",
  "Recover Email": "Recover Email",
  Redo: "Redo",
  Register: "Register",
  "Remove All": "Remove All",
  Rename: "Rename",
  Replace: "Replace",
  Reset: "Reset",
  "Reset Password": "Reset Password",
  "Reset Transformation": "Reset Transformation",
  Result: "Result",
  Rings: "Rings",
  "Road Connections": "Road Connections",
  Role: "Role",
  "Rotate/Flip": "Rotate/Flip",
  "Rotate 180": "Rotate 180°",
  "Rotate Left": "Rotate Left",
  "Rotate Right": "Rotate Right",
  Round: "Round",
  Save: "Save",
  Scratchboard: "Scratchboard",
  "Scroll threshold": "Scroll threshold",
  "Scrub Bar": "Scrub Bar",
  "Scrub with scroll wheel": "Scrub with scroll wheel",
  Search: "Search",
  "Search Game Selector": "Search Game Selector",
  "Select/Drop All": "Select/Drop All",
  "Select/Drop Next Friendly": "Select/Drop Next Friendly",
  Share: "Share",
  "Share PTN": "Share PTN",
  "Share TPS": "Share TPS",
  "Share URL": "Share URL",
  "Short Link": "Short Link",
  "Show All Branches": "Show All Branches",
  "Show Analysis": "Show Analysis",
  "Show Branch Menu": "Show Branch Menu",
  "Show Chat": "Show Chat",
  "Show Header Tags": "Show Header Tags",
  "Show More/Less": "Show More/Less",
  "Show Notes": "Show Notes",
  "Show PTN": "Show PTN",
  "Show Text": "Show Text",
  "Show Evaluation": "Show Evaluation",
  Site: "Site",
  Size: "Size",
  Spectate: "Spectate",
  Spectator: "Spectator",
  "Stack Counts": "Stack Counts",
  "Start from Current Position": "Start from Current Position",
  "Starting Position": "Starting Position",
  "Stone Border Width": "Stone Border Width",
  Style: "Style",
  "Switch Player": "Switch Player",
  "Switch Text Tab": "Switch Text Tab",
  TPS: "TPS", // Tak Positional System
  "Table View": "Table View",
  Tak: "Tak",
  "Text Size": "Text Size",
  Theme: "Theme",
  Time: "Time",
  Tinue: "Tinuë",
  today: "today",
  tomorrow: "tomorrow",
  "Transform Board": "Transform Board",
  "Transparent Background": "Transparent Background",
  Trim: "Trim",
  "Trim Branches": "Trim Branches",
  "Trim to Current Board": "Trim to Current Board",
  "Trim to Current Ply": "Trim to Current Ply",
  "Turn Indicator": "Turn Indicator",
  UI: "UI", // User Interface
  "UI Hints": "UI Hints",
  "UI Options": "UI Options",
  "UI Preferences": "UI Preferences",
  Undo: "Undo",
  "Unplayed Pieces": "Unplayed Pieces",
  Update: "Update",
  Usage: "Usage",
  "Verify Email Address": "Verify Email Address",
  "View Game Info": "View Game Info",
  Walls: "Walls",
  Width: "Width",
  yesterday: "yesterday",
  "?": "?",
  "??": "??",
  "!": "!",
  "!!": "!!",

  result: {
    1: "{player} wins by default",
    F: "{player} wins by flats",
    R: "{player} wins by road",
    tie: "Draw",
  },

  openings: {
    swap: "Swap",
    "no-swap": "No Swap",
  },

  theme: {
    // Names
    aer: "Aer",
    aether: "Aether",
    aqua: "Aqua",
    atlas: "Atlas",
    backlit: "Backlit",
    bubbletron: "BubbleTron",
    classic: "Classic",
    discord: "Discord",
    fresh: "Fresh",
    ignis: "Ignis",
    luna: "Luna",
    moss: "Moss",
    paper: "Paper",
    retro: "Retro",
    stealth: "Stealth",
    terra: "Terra",
    zen: "Zen",

    // Board Styles
    blank: "Blank",
    diamonds1: "Diamonds Small",
    diamonds2: "Diamonds Medium",
    diamonds3: "Diamonds Large",
    grid1: "Grid Small",
    grid2: "Grid Medium",
    grid3: "Grid Large",

    // Colors
    primary: "Primary",
    secondary: "Background",
    ui: "UI",
    accent: "UI Accent",
    panel: "Panel Background",
    board1: "Board Light",
    board2: "Board Dark",
    board3: "Board Unplayed",
    player1: "Player 1",
    player1road: "Player 1 Road",
    player1flat: "Player 1 Flat Stone",
    player1special: "Player 1 Special Stone",
    player1border: "Player 1 Stone Border",
    player2: "Player 2",
    player2road: "Player 2 Road",
    player2flat: "Player 2 Flat Stone",
    player2special: "Player 2 Special Stone",
    player2border: "Player 2 Stone Border",
    ring1: "Ring 1",
    ring2: "Ring 2",
    ring3: "Ring 3",
    ring4: "Ring 4",
    textLight: "Light Text",
    textDark: "Dark Text",
    umbra: "Shadow",
  },

  confirm: {
    abandonChanges: "Abandon unsaved changes?",
    downloadMultipleGames: "Download 1 game? | Download {count} games?",
    logInTitle: "Are you sure you want to proceed?",
    logInMessage:
      "Logging in will abandon all private games! Cancel and register a new account to avoid losing them.",
    logInOK: "Proceed and Abandon Games",
    removeGame: "Are you sure you want to remove this game?",
    resetEmbed: "Reset embed settings to default?",
    resetGame: "Reset the game?",
    resetPassword: "Reset the password for {email}?",
    resetGIF: "Reset GIF settings to default?",
    resetPNG: "Reset PNG settings to default?",
    resetTheme: "Reset the theme?",
  },

  error: {
    "Already joined": "You have already joined the game",
    "Bot unavailable": "The selected bot is currently unavailable",
    "Branch already exists": "That branch already exists.",
    "Database not supported": "This browser doesn't support IndexedDB.",
    "Database outdated":
      "The local database is outdated. Please reload the page.",
    "Failed to create game": "Failed to create game",
    "Failed to join game": "Failed to join game",
    "Game does not exist": "That game does not exist.",
    "Invalid branch": "Invalid branch",
    "Invalid branch name": "Invalid branch name",
    "Invalid caps": "Invalid capstone count",
    "Invalid caps1": "Invalid capstone 1 count",
    "Invalid caps2": "Invalid capstone 2 count",
    "Invalid clock": "Invalid clock",
    "Invalid comment": "Invalid comment",
    "Invalid date": "Invalid date",
    "Invalid evaluation": "Invalid evaluation",
    "Invalid event": "Invalid event",
    "Invalid first move": "Invalid first move",
    "Invalid first line number": "Invalid first line number",
    "Invalid flats": "Invalid flat stone count",
    "Invalid flats1": "Invalid flat stone 1 count",
    "Invalid flats2": "Invalid flat stone 2 count",
    "Invalid komi": "Invalid komi",
    "Invalid line number": "Invalid line number",
    "Invalid move": "Invalid move",
    "Invalid opponent name": "Invalid opponent name",
    "Invalid player1": "Invalid player 1",
    "Invalid player2": "Invalid player 2",
    "Invalid player name": "Invalid player name",
    "Invalid points": "Invalid points",
    "Invalid PTN format": "Invalid PTN format",
    "Invalid rating1": "Invalid rating 1",
    "Invalid rating2": "Invalid rating 2",
    "Invalid result": "Invalid result",
    "Invalid round": "Invalid round",
    "Invalid site": "Invalid site",
    "Invalid size": "Invalid size",
    "Invalid tag": "Invalid tag",
    "Invalid time": "Invalid time",
    "Invalid tps": "Invalid TPS",
    "Missing board size": "Missing board size",
    "No open seats": "The game has no open seats",
    Offline: "Offline",
    "Player exists": "That name is already taken",
    "Unable to download": "Unable to download files",
    "Unable to read clipboard": "Unable to read clipboard",
    unknown: "An unknown error occurred",
    "Unrecognized tag": "Unrecognized tag",
    UnvalidatedEmail: "Please validate your email address.",
    "URL alias not found": "This link does not exist",
    "auth/invalid-email": "Invalid email address",
    "auth/user-not-found": "That email address is not registered to a user.",
    "auth/wrong-password": "Incorrect password",
    localstorageFull: "LocalStorage is full; please close some games.",
    unauthenticated: "You must be logged in to do that",
  },

  success: {
    closedMultipleGames: "Closed 1 game | Closed {count} games",
    copied: "Copied!",
    copiedItem: "Copied {item}",
    emailRecovered: "Email address restored",
    emailVerified: "Email address verified",
    passwordChanged: "Password changed",
    replacedExistingGame: "Replaced existing game of the same name",
    resetPasswordSent: "Reset password email sent.",
    themeRemoved: 'Removed theme "{name}"',
    updateAvailable: "A new version is available",
    verifyEmailSent: "Verification email sent.",
  },

  warning: {
    logIn:
      "Logging in will abandon all private games! Register an account to keep them.",
  },

  hint: {
    autodetectScroll: "Scroll one mouse wheel tick here",
    optional: "(Optional)",
    pasteThemeCode: "Paste theme code here",
    playerNamePrivate: "Whatever you want to call yourself",
    playerNamePublic: "Your unique name",
    privateGame: "This game is visible only to players",
    publicGame: "This game is listed publicly",
    scratchboard: "Explore your options before committing",
    spectate: "Share this with other spectators",
    tpsEditMode: "Edit in TPS Mode",
    url: "Share this with your opponent and spectators",
  },

  hotkeys: {
    CONTROLS: "Play Controls",
    DIALOGS: "Dialogs",
    EVAL: "Evaluation",
    HIGHLIGHTER: "Highlighter",
    MISC: "Miscellaneous",
    MOVES: "Movements",
    PIECE: "Piece Selector (TPS Mode)",
    SHARING: "Sharing",
    TRANSFORMS: "Board Transformations",
    UI: "UI Toggles", // User Interface
    "0-9": "0–9",
    Alt: "Alt",
    Arrowdown: "▼",
    Arrowleft: "◀",
    Arrowright: "▶",
    Arrowup: "▲",
    Backspace: "Backspace",
    Ctrl: "Ctrl",
    Del: "Delete",
    Enter: "Enter",
    Esc: "Escape",
    Shift: "Shift",
    Space: "Space",
  },

  analysis: {
    "analyzed positions":
      "No analyzed positions | 1 analyzed position | {count} analyzed positions",
    "Analyze Branch": "Analyze Branch",
    "Analyze Game": "Analyze Game",
    "Analyze Position": "Analyze Position",
    "Think Harder": "Think Harder",
    "Bot Moves": "Bot Suggestions",
    "Database Moves": "PlayTak Openings",
    depth: "depth",
    Depth: "Depth",
    draws: "draw | draws",
    gameOver: "The game has ended",
    gameType: "Game Type",
    includeBotGames: "Include Bot Games",
    interactiveAnalysis: "Interactive Analysis",
    maxDate: "Before",
    maxSuggestedMoves: "Max. Suggestions",
    minDate: "After",
    n_games: "1 game | {count} games",
    "No suggested moves": "No suggested moves",
    nodes: "1 node | {count} nodes",
    nps: "nps",
    seconds_unit: "sec",
    timeBudget: "Seconds to Think",
    "Top Games from Position": "Top Games from Position",
    tournamentGame: "Tournament Game",
    visits: "1 visit | {count} visits",
    wins: "win | wins",
    bots: {
      "tiltak-cloud": "Tiltak (cloud)",
      tiltak: "Tiltak (wasm)",
      topaz: "Topaz (wasm)",
    },
    bots_description: {
      "tiltak-cloud": "Full Game Analysis",
      tiltak: "Interactive Analysis",
      topaz: "Deep Analysis",
    },
    database: {
      error: "Failed to load databases",
      loading: "Loading database list",
      newPosition: "No data for this position or search filter",
      notFound:
        "No database available for the current board size or search filters",
    },
    tournamentOptions: {
      exclude: "Non-Tournament",
      only: "Tournament",
    },
  },

  format: {
    "date-standard": "yyyy/MM/dd",
    "date-time-standard": "yyyy/MM/dd HH:mm",
    "date-short": "M/d/yyyy",
    "date-time-short": "M/d/yyyy h:mma",
    "date-full": "E, MMM d, yyyy",
    "date-time-full": "E, MMM d, yyyy h:mma",
    "date-ptn": "yyyy.MM.dd",
    "time-ptn": "HH:mm:ss",
  },
};
