
export default {
  name: "small-dialog",
  props: {
    value: Boolean,
    goBack: Boolean,
    noMaximize: Boolean,
    width: String,
  },
  computed: {
    model() {
      return this.value;
    },
    maximized() {
      return (
        !this.noMaximize &&
        (this.$q.screen.width < this.$q.screen.height
          ? this.$q.screen.height <= this.$q.screen.sizes.sm
          : this.$q.screen.width <= this.$q.screen.sizes.xs)
      );
    },
  },
  methods: {
    hide() {
      if (this.goBack) {
        this.$router.back();
      }
    },
  },
};
