import { render, staticRenderFns } from "./DialogHeader.vue?vue&type=template&id=cf227a92"
import script from "./DialogHeader.vue?vue&type=script&lang=js"
export * from "./DialogHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@quasar/app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QIcon,QItemLabel,QBtn});qInstall(component, 'directives', {ClosePopup});
